.search-sort-container {
  float: right;
  margin-right: 24px;
  padding: 4px;
  height: 100%;
  align-items: center;
  display: flex;
  background: white;
}

.search-stack {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  height: 100%;
  margin-right: 6px;
  border-radius: 100px;
  transition: transform 0.5s;
  position: relative;
}

.search-stack-results {
  width: 100%;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 41px;
  position: absolute;
  padding: 0.5rem 0;
  border-top: none;
  margin: 0.125rem 0 0;
  background-color: #fff;
  max-height: 40vh;
  overflow-y: auto;
}

.search-stack .tags-list {
  margin-bottom: 0px;
}

.search-stack .hidden {
  display: none;
}

.icon-only {
  width: 38px;
  background: #eee;
  box-shadow: none;
  cursor: pointer;
  height: 38px;
}

.icon-only .search-stack-header {
  width: 100%;
}

.search-stack .tags-list button,
.search-stack .selected-tags-list button {
  color: #212529;
  border-radius: 0;
}

.search-stack .selected-tags-list {
  padding: 0 0 0.5rem 0;
  margin: 0 0 0.5rem 0;
}

.search-stack-results .empty {
  padding: 0 0 0.5rem 0;
}

.search-stack-results-header {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  font-style: italic;
  color: rgb(150, 150, 150);
}

.search-stack-results-body {
  padding: 0.5rem 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.search-tags-list {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 0 12px 0;
  margin: 0 0 12px 0;
  display: flex;
}

.search-tags-input-container,
.search-stack-input-container {
  width: 100%;
}

.search-tags-list input,
.search-stack input {
  border-radius: 5px;
  width: 95%;
  box-shadow: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  border: none;
}

.search-tags-header,
.search-stack-header {
  padding: 0 8px;
  /*color: rgba(150, 150, 150, 1);*/
  align-items: center;
  display: flex;
  justify-content: center;
}

.search-tags-list button,
.search-stack button {
  border: none;
  background: none;
  border-radius: 100px;
  padding: 0 12px;
  color: rgba(150, 150, 150, 1);
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .searching {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    background: white;
    z-index: 100;
    border-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    height: 48px;
  }
  .searching .search-stack-results {
    top: 45px;
  }
}

/* for iPhone 5/SE */
@media screen and (max-width: 320px) {
}
