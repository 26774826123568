.image-viewer {
  height: 33%;
  padding: 0 !important;
  background: linear-gradient(#ffffff, rgba(28, 182, 245, 0.3));
  /*background: linear-gradient(rgba(28, 182, 245, 0.3), #ffffff);*/
  /*max-height: 33%;*/
  position: sticky;
  bottom: 48px;
}

.image-viewer input[type='file'] {
  display: none;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .dropdown .main-menu {
    padding-bottom: 72px;
  }
  body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  html {
    min-height: -webkit-fill-available;
  }
}

/* for iPhone 5/SE */
@media screen and (max-width: 320px) {
}
