#tag-interface {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: fixed;
  bottom: 36px;
  left: 50%;
  transform: translate(-50%, 0);
  border: none;
  background: none;
  z-index: 10;
}

#tag-interface .arrow {
  height: 64px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border: none;
  border-radius: 1000px;
  min-width: 64px;
  color: rgb(33, 37, 41);
}

#tag-interface .tag {
  height: 64px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  border: none;
  border-radius: 10px;
  min-width: 64px;
  margin: 0 12px;
  -webkit-transition: width 0.3s ease-in-out !important;
  -moz-transition: width 0.3s ease-in-out !important;
  -ms-transition: width 0.3s ease-in-out !important;
  -o-transition: width 0.3s ease-in-out !important;
  transition: width 0.3s ease-in-out !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#tag-interface .tag span {
  padding: 10px;
  border-radius: 5px;
  color: rgb(33, 37, 41);
  background: rgb(255, 255, 255, 1);
}

#tag-interface .tag svg {
  color: rgb(33, 37, 41);
}

#tag-interface .tagged {
  border: 1px solid rgba(28, 182, 245, 2);
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .tag span {
    display: none;
  }
}

/* for iPhone 5/SE */
@media screen and (max-width: 320px) {
  .tag span {
    display: none;
  }
}
