.App-main article {
  display: flex;
}

.App-main-logo {
  margin: 10px 0 0 0;
  display: flex;
}

.App-main-phone {
  flex-basis: 300px;
  display: flex;
  position: relative;
}

.App-main-phone .carousel {
  width: 86%;
  height: 55%;
  position: absolute;
  left: 7%;
  top: 8%;
}

.App-phone {
  height: 600px;
}

.App-main-text {
  margin: 10px 0 0 0;
  flex-direction: column;
  text-align: center;
  line-height: 1.3;
}

.App-main-text span {
  font-size: 20px;
  font-weight: 300;
}

.App-main-text h2 {
  margin: 50px 0 0 0;
  font-size: 28px;
  font-weight: 300;
}

.App-main-form {
  margin: 0 0 0 50px;
  flex-direction: column;
  display: flex;
  width: 100%;
  max-width: 400px;
}

.App-main-text .emailForm {
  display: block;
}

.App-main-text .emailFormMessage {
  display: block;
  font-weight: 300;
  font-size: 20px;
  margin: 30px 0 0 0;
}

.App-main-text .email {
  margin: 20px 0;
  padding: 0;
  height: 40px;
  width: 80%;
  text-align: center;
  font-size: 20px;
  background: none;
  color: gray;
  border-radius: 2px;
  border: 1px solid lightgray;
  -webkit-appearance: none;
  outline: none;
}

.App-main-text .email::placeholder {
  color: lightgray;
  opacity: 1;
  transition: opacity 0.5s;
}

.App-main-text .email:focus::placeholder {
  opacity: 0;
}

.App-main-text .reserve {
  margin: 0;
  padding: 0;
  width: 50%;
  height: 50px;
  font-size: 24px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 1px 1px 1px lightgray;
  border: 0;
  background: linear-gradient(#fe9800, #f5cc87);
  font-weight: 500;
  cursor: pointer;
  outline: none;
}

.App-main-text .reserve:active {
  filter: saturate(125%);
}

.App-footer {
  height: 7vh;
  display: flex;
}

.App-footer-nav {
  width: 100%;
  padding: 2.5vh 0;
}

.App-footer-nav a {
  text-decoration: none;
  padding: 0 20px 0 20px;
  color: darkgray;
}

.App-footer-nav a:visited {
  color: darkgray;
}

.App-footer-nav span {
  padding: 0 20px 0 20px;
  color: darkgray;
}

.App-logo {
  pointer-events: none;
  height: 90px;
  width: 90px;
  object-fit: cover;
  object-position: -265px 0px;
  margin: 4px 2px;
}

.App-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: black;
  max-width: 800px;
  margin: auto;
}

.App-main .hero {
  margin: -6px auto 0 auto;
  overflow: hidden;
  text-indent: 110%;
  white-space: nowrap;
  width: 300px;
  height: 110px;
  background-repeat: no-repeat;
  background-position: 6px 0px;
  background-size: 400px;
}

.App-main p {
  font-size: 16px;
}

.App-main li {
  font-size: 16px;
}

.App-main li a {
  text-decoration: none;
  color: darkgray;
}

.App-main li a:visited {
  color: darkgray;
}

.App-link {
  color: #61dafb;
}

.privacy {
  text-align: justify;
  width: 80%;
}

.App-main-login {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-radius: 0 0 5px 5px;
  position: relative;
  overflow: hidden;
}

.App-main-login #loading {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.App-main-login #loading .placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 90%
  );
  -webkit-mask-image: linear-gradient(
    130deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 90%
  );
}

.App-main-login #loading .placeholder {
  background-color: rgb(28, 182, 245, 1);
}

@media screen and (max-width: 991px) {
  /* start of large tablet styles */

  .App-main-form {
    margin: 0 0 0 20px;
  }
}

@media screen and (max-width: 767px) {
  /* start of medium tablet styles */

  .App-main-phone {
    display: none;
  }

  .App-main-form {
    margin: 0;
  }

  .App-main {
    /*min-height: 75vh;*/
    height: 100%;
  }
}

@media screen and (max-width: 479px) {
  /* start of phone styles */

  .App-main .hero {
    margin: 25px auto 0 auto;
    overflow: hidden;
    text-indent: 110%;
    white-space: nowrap;
    width: 340px;
    height: 110px;
    background-repeat: no-repeat;
    background-position: 6px 0px;
    background-size: 340px;
  }

  .App-main-logo {
    margin: 0;
    display: block;
  }

  .App-main-logo img {
    display: none;
  }

  .App-main-logo h1 {
    margin: 0;
  }

  .App-logo {
    margin: 14px;
  }

  .App-main-text {
    margin: 0;
  }

  .App-main-text span {
    font-size: 18px;
  }

  .App-main-text h2 {
    margin: 10px 0 0 0;
    font-size: 24px;
  }

  .App-main-text .email {
    width: 80%;
  }

  .App-main-text .reserve {
    width: 80%;
  }

  .App-main-login {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .App-main-login .Form__formSection___1PPvW {
    padding: 20px 40px !important;
  }
}

/* for iPhone 5/SE */
@media screen and (max-width: 320px) {
  .App-main .hero {
    margin: 25px auto 0 auto;
    overflow: hidden;
    text-indent: 110%;
    white-space: nowrap;
    width: 300px;
    height: 90px;
    background-repeat: no-repeat;
    background-position: 6px 0px;
    background-size: 300px;
  }

  .App-main-text span {
    font-size: 16px;
  }

  .App-main-login .Form__formSection___1PPvW {
    padding: 12px 20px !important;
  }

  .App-main-login .Section__sectionFooterPrimaryContent___2r9ZX {
    margin-bottom: 16px !important;
  }
}
