.stack-sidebar-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  width: 100%;
  background: rgba(255, 255, 255, 1);
}

.stack-sidebar-footer button {
  width: 25%;
  border-radius: 0;
  color: #212529;
}

@media screen and (max-width: 991px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 479px) {
  .stack-sidebar-footer {
    height: 72px;
  }
}

/* for iPhone 5/SE */
@media screen and (max-width: 320px) {
}
