body {
  margin: 0;
  height: 100%;
  min-width: 320px;
  /*min-height: 100vh;*/
  min-height: -webkit-fill-available;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar-track {
  background: #f1f120;
}

*:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.container {
  height: 100vh;
  /*padding: 2vh;   */
  padding: 0 16px;
  /*padding: 0 !important;*/
  /*max-width: 800px !important;*/
  /*max-width: 80vh !important;*/
}

@media only screen and (max-width: 400px) and (orientation: portrait) {
  .container {
    /*padding: 2vh 2vh 18vh 2vh;*/
    padding: 0;
  }

  .box-deck {
    padding-bottom: 48px !important;
  }

  .box-deck .flat:hover {
    box-shadow: none !important;
    -webkit-box-shadow: none !important; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none !important; /* Firefox 3.5 - 3.6 */
  }

  .dropdown .main-menu {
    height: calc(100%);
    /*min-width: 300px;*/
    width: 100% !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .container {
    /*padding: 2vh 2vh 10vh 2vh; */ /* used to be 18vh */
    padding: 0;
  }

  .box-deck .flat:hover {
    box-shadow: none;
    -webkit-box-shadow: none; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none; /* Firefox 3.5 - 3.6 */
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .container {
    /*padding: 2vh 2vh 10vh 2vh;*/
    padding: 0;
  }

  .box-deck .flat:hover {
    box-shadow: none;
    -webkit-box-shadow: none; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none; /* Firefox 3.5 - 3.6 */
  }
}

.row {
  height: 100%;
  margin: 0 !important;
}

.col {
  height: 100%;
  padding: 0 !important;
}

.card {
  height: 100%;
  border: none !important;
  border-radius: 0 !important;
  margin: 0 auto;
  /*max-width: 80vh;*/
}

.error-modal .modal-header {
  background: #dc3545;
  color: white;
}

.error-modal .modal-header span {
  vertical-align: middle;
  margin-left: 10px;
}

.error-modal .close {
  opacity: 1;
  color: white;
}

.error-modal .close:hover {
  opacity: 0.5;
  color: white;
}

.model-viewer {
  background: linear-gradient(
    rgba(255, 255, 255, 0.85) 10%,
    rgba(255, 255, 255, 0.75) 90%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100%;
  height: 100%;
}

model-viewer {
  --poster-color: transparent;
}

.model-viewer-actions {
  position: absolute;
  right: 8px;
  z-index: 1;
  width: 48px;
  /*
  margin-top: 8px;
  top: 48px;
  */
}

.card-header .btn,
.card-body .btn {
  padding: 12px;
  line-height: initial;
  border: none;
  font-size: initial;
}

.btn-rotate {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  color: rgba(28, 182, 245, 1) !important;
  -webkit-transition: opacity 0.3s ease-in-out !important;
  -moz-transition: opacity 0.3s ease-in-out !important;
  -ms-transition: opacity 0.3s ease-in-out !important;
  -o-transition: opacity 0.3s ease-in-out !important;
  transition: opacity 0.3s ease-in-out !important;
}

.btn-rotate:hover {
  opacity: 0.8;
  color: rgba(28, 182, 245, 1) !important;
}

.btn-maker-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;
  border-radius: 0 !important;
  color: rgba(28, 182, 245, 1) !important;
  background: radial-gradient(white, transparent);
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out !important;
  -moz-transition: opacity 0.3s ease-in-out !important;
  -ms-transition: opacity 0.3s ease-in-out !important;
  -o-transition: opacity 0.3s ease-in-out !important;
  transition: opacity 0.3s ease-in-out !important;
}

.btn-maker-add:hover {
  opacity: 1;
}

.btn-cancel-file {
  z-index: 1001;
  position: absolute;
  right: 0;
  -webkit-transition: opacity 0.3s ease-in-out !important;
  -moz-transition: opacity 0.3s ease-in-out !important;
  -ms-transition: opacity 0.3s ease-in-out !important;
  -o-transition: opacity 0.3s ease-in-out !important;
  transition: opacity 0.3s ease-in-out !important;
}

.btn-cancel-file:hover {
  opacity: 0.35;
}

.double-rotate-button {
  position: relative;
}

.double-rotate-button span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
}

.blinking:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  background: #6c757d;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none !important;
}

.unselectable:focus-within {
  outline: none !important;
}

#modelViewer::shadow .container canvas {
  padding: 50px;
}

.sketch-picker {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.loadingSpinner {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 999;
  display: flex;
  justify-content: safe center;
  align-items: center;
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  /*opacity: 75% !important;  /* Not sure why Chrome deployed is overriding this */
  min-height: 30vh;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.loading ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}

.loading ul li {
  text-align: center;
  box-sizing: border-box;
  line-height: 0;
}

.loading ul li div {
  box-shadow: 0 2px 2px 0 #bbb;
  -webkit-box-shadow: 0 2px 2px 0 #bbb;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: inline-flex;
  box-sizing: border-box;
  background: linear-gradient(
    to left,
    rgb(187, 187, 187) 0%,
    rgb(255, 255, 255) 3.74211%,
    rgb(255, 255, 255) 96.2579%,
    rgb(187, 187, 187) 100%
  );
}

.loading .placeholder,
.loading span {
  background-color: #ccc;
  height: 100%;
  width: 100%;
  display: inherit;
}

.loading .placeholder-wave {
  height: 100%;
  width: 100%;
  mask-image: linear-gradient(
    130deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 90%
  );
  -webkit-mask-image: linear-gradient(
    130deg,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 90%
  );
}

.box-viewer {
  /* overflow-y: scroll; */
  padding: 0rem !important;
  display: flex; /* defines flexbox */
  flex-direction: column; /* top to bottom */
  justify-content: space-between; /* first item at start, last at end */
}

.selected-tags-list {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  list-style: none;
  padding: 0 0 12px 0;
  margin: 0 0 12px 0;
}

.stack-base {
  height: 32px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(238, 238, 238, 0),
    #eee,
    rgba(238, 238, 238, 0)
  );
}

.box-deck,
.box-list,
.tags-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.box-list,
.tags-list {
  margin-bottom: 64px;
}

.tags-list button,
.selected-tags-list button {
  min-height: 32px;
}

.box-deck li,
.box-list li {
  display: flex;
}

.box-deck button,
.box-list button,
.tags-list button,
.selected-tags-list button {
  /*min-width: 80%;*/
  min-width: 146px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;
  transition: height 0.1s ease-out;
  width: 100%;
}

.touch-flat-container {
  min-width: 146px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  position: relative;
  width: 100%;
  padding: 0 0.5rem;
}

.touch-flat-header {
  padding: 0 0.5rem;
  text-align: center;
  white-space: nowrap;
}

.touch-flat-footer {
  padding: 0 0.5rem;
  text-align: center;
  white-space: nowrap;
}

.box-deck-container {
  height: 100%;
  /*padding: 24px 0;*/
  padding: 0;
  overflow-y: scroll;
  overscroll-behavior: contain;
}

.main-menu .scrollbar-container {
  padding: 0;
  height: calc(100% - 48px);
  margin-top: 48px;
}

.main-menu .scroll-div div {
  overscroll-behavior: contain;
}

/*
.box-deck .flat.selected {
  background: rgba(100, 100, 100, 1);
}
*/

.box-deck .expanded {
  height: 24px;
}

.box-deck .selected {
  background: rgba(28, 182, 245, 0.3);
  /*transition: margin 0.1s ease-out; */
  /*font-weight: bold;*/
  /*margin-top: 20px; 
  /*margin-bottom: 20px;*/
  height: 32px;
}

.box-deck span {
  float: right;
  align-items: center;
  height: 100%;
  /*position: absolute;
  right: 2.0rem;*/
  /*vertical-align: middle;*/
}

.box-deck span time {
  /*vertical-align: middle;*/
}

.box-list button:hover,
.tags-list button:hover,
.selected-tags-list button:hover {
  background: rgba(28, 182, 245, 0.3);
}

.tags-list .tag-header,
.selected-tags-list .tag-header {
  min-width: 50px;
  white-space: nowrap;
  text-align: right;
  padding: 0 12px;
  color: black;
}

.tags-list .tag-body,
.selected-tags-list .tag-body,
.search-tag-body {
  color: black;
  text-align: left;
  width: 100%;
}

.tags-list .tag-footer,
.selected-tags-list .tag-footer {
  min-width: 50px;
  white-space: nowrap;
  text-align: right;
  padding: 0 12px;
  height: 24px;
}

.search-tag-header,
.search-tag-footer {
  min-width: 50px;
  padding: 0 12px;
  color: rgba(150, 150, 150, 1);
}

.search-tag-header {
  text-align: center;
}

.search-tag-footer {
  text-align: right;
}

.search-tag-header svg,
.search-tag-footer svg {
  width: 1rem;
}

/*
.box-list .flat-header {
  min-width: 60px;
  white-space: nowrap;
  text-align: right;
  padding: 0 16px 0 3px;
}

.box-list .flat-footer {
  min-width: 60px;
  white-space: nowrap;
  text-align: right;
  padding: 0 16px 0 3px;
  height: 24px;
}
*/

.box-deck .flat-header {
  /*min-width: 10%;*/
  min-width: 80px;
  padding: 0px 3px 0 16px;
  text-align: left;
  white-space: nowrap;
  font-size: 14px;
  line-height: 32px;
}

.box-deck .flat-footer {
  /*min-width: 10%;*/
  min-width: 80px;
  padding: 0px 16px 0 3px;
  text-align: right;
  white-space: nowrap;
  font-size: 14px;
  line-height: 32px;
}

.box-deck .flat {
  background: #eee;
  position: relative;
  display: inline-flex;
  float: left;
  height: 10px;
  box-shadow: 0px 2px 2px 0px #bbb;
  -webkit-box-shadow: 0px 2px 2px 0px #bbb; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 2px 2px 0px #bbb; /* Firefox 3.5 - 3.6 */
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  width: 100%;
  -webkit-transition: transform 50ms ease-out 50ms;
  -moz-transition: transform 50ms ease-out 50ms;
  -o-transition: transform 50ms ease-out 50ms;
  transition: transform 50ms ease-out 50ms;
}

.box-deck .selected .flat {
  transform: scale(1.05);
  z-index: 9;
}

.box-deck .flat:hover {
  transform: scale(1.05);
  z-index: 9;
  box-shadow: 0px 0px 5px 0px rgba(28, 182, 245, 1);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(28, 182, 245, 1); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 0px 5px 0px rgba(28, 182, 245, 1); /* Firefox 3.5 - 3.6 */
}

.box-deck .tagged {
  /* background: rgba(28, 182, 245, 1); */
  /* background: linear-gradient( , 0%,  100%)`; */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(28, 182, 245, 1) 40%,
    rgba(28, 182, 245, 1) 60%,
    rgba(255, 255, 255, 0.1) 100%
  );
  width: 100%;
  height: 100%;
  float: left;
  min-height: 1px;
}

.dropdown .edge-menu {
  transform: none !important;
  border-radius: 5px 0 0 5px;
  border-right: none;
  inset: 47px 0 auto auto !important;
}

.dropdown .radius-menu {
  transform: none !important;
  border-radius: 5px 0 0 5px;
  border-right: none;
  inset: 47px -48px auto auto !important;
}

.dropdown .thickness-menu {
  transform: none !important;
  border-radius: 5px 0 0 5px;
  border-right: none;
  inset: 47px -96px auto auto !important;
}

.dropdown .main-menu {
  margin: 0;
  border-radius: 0;
  border-top: none;
  border-bottom: none;
  border-left: none;
  position: fixed !important;
  height: calc(100%);
  min-width: 300px;
  width: 25%;
  padding: 0;
  padding-top: 48px;
  padding-bottom: 48px;
  /*background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.25));*/
}

.add-stacks-modal .save-button {
  background: rgba(28, 182, 245, 1);
  border: 1px solid rgba(28, 182, 245, 1);
}

.add-stacks-modal .save-button:hover {
  background: rgba(28, 182, 245, 0.75);
  border: 1px solid rgba(28, 182, 245, 0.75);
}

.add-stacks-modal .form-control:focus {
  border: 1px solid rgba(28, 182, 245, 0.75);
}

.add-stack-modal .modal-content {
  padding: 0.5rem;
}

.main-menu .tags-button-cluster {
  position: absolute;
  bottom: 60px;
  left: 50%;
  margin: 0 auto;
  transform: translate(-50%, 0);
  display: flex;
}

.sidebar-add-tag-button,
.sidebar-tag-tag-button,
.sidebar-edit-tag-button,
.sidebar-search-tag-button {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1000px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  margin: 5px;
}

.tags-button-cluster .activated {
  color: rgba(28, 182, 245, 1) !important;
}

.tags-button-cluster button {
  width: 40px;
  height: 40px;
  padding: 0 !important;
  border-radius: 1000px;
  color: rgb(33, 37, 41);
}

.main-menu .model-maker-sidebar-buttons {
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1000px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
  z-index: 1000;
  min-width: 180px;
  display: flex;
}

.main-menu .model-maker-sidebar-buttons button {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 1000px;
}

.main-menu .model-maker-sidebar-buttons .maker-completed {
  position: absolute;
  left: -6px;
  top: -12px;
  background: rgba(220, 220, 220, 1);
  padding: 0 6px;
  border-radius: 100px;
  color: rgba(50, 50, 50, 0.5);
  min-width: 24px;
  min-height: 24px;
  text-align: center;
}

.main-menu .model-maker-sidebar-buttons .maker-pending {
  position: absolute;
  right: -6px;
  top: -12px;
  background: rgba(220, 220, 220, 1);
  padding: 0 6px;
  border-radius: 100px;
  color: rgba(50, 50, 50, 0.5);
  min-width: 24px;
  min-height: 24px;
  text-align: center;
}

.slide-in#stack-sidebar {
  transform: translate3d(0, 0, 0) !important;
  position: relative;
}

#stack-sidebar {
  transform: translate3d(-100%, 0, 0) !important;
  display: block !important;
  -webkit-transition: transform 300ms ease-out 200ms;
  -moz-transition: transform 300ms ease-out 200ms;
  -o-transition: transform 300ms ease-out 200ms;
  transition: transform 300ms ease-out 200ms;
  box-shadow: 1px 1px 10px 0px rgba(200, 200, 200, 0.5);
}

.stack-sidebar-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 48px;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  z-index: 10;
}

.stack-sidebar-header .title {
  height: 100%;
  vertical-align: middle;
}

#dropdown-tags-view-selector::after {
  display: none;
}

#dropdown-stack-view-selector {
  padding: 7px;
  background: #eee;
  border-radius: 100px;
}

#dropdown-stack-view-selector::after {
  display: none;
}

#dropdown-main-menu::after {
  display: none;
}

.card-header .dropdown-item,
.card-body .dropdown-item {
  padding: 0.25rem 16px 0.25rem 12px;
  height: 36px;
}

.card-header .user-selector {
  transform: translate3d(8px, 48px, 0px) !important;
  border-radius: 0 0 0 5px;
  border-right: none;
  border-top: none;
}

.username {
  float: right;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

#dropdown-user-selector::after {
  /*display: none;*/
}

.card-body .download-selector {
  transform: translate3d(-48px, 22px, 0px) !important;
  border-radius: 8px 0 8px 8px;
  margin: 0;
  /*border-right: none;
  border-top: none;*/
}

#dropdown-download-selector::after {
  display: none;
}

.card-body .edit-selector {
  transform: translate3d(-48px, 22px, 0px) !important;
  border-radius: 8px 0 8px 8px;
  margin: 0;
  /*border-top: none;*/
}

#dropdown-download-selector,
#dropdown-edit-selector {
  color: #666;
}

#dropdown-radius-selector {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header .stack-view-menu {
  transform: none !important;
  border-radius: 0 0 5px 5px;
  border-top: none;
  inset: 43px -10px auto auto !important;
}

#dropdown-edit-selector::after {
  display: none;
}

#menuSelector::after {
  display: none;
}

#dropdown-thickness-selector::after {
  display: none;
}

#dropdown-edge-selector::after {
  display: none;
}

#dropdown-radius-selector::after {
  display: none;
}

#og-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: fixed;
  bottom: 12px;
  right: 12px;
  border: none;
}

#tag-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: fixed;
  bottom: 88px;
  right: 12px;
  border: none;
  color: rgb(33, 37, 41);
}

#blackbox-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: fixed;
  bottom: 12px;
  right: 88px;
  border: none;
  color: rgb(33, 37, 41);
}

#gifts-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px;
  pointer-events: initial;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  position: fixed;
  bottom: 72px;
  right: 72px;
  border: none;
  color: rgb(33, 37, 41);
}

#og-maker {
  height: 100%;
  width: 100%;
  margin-top: 48px;
  position: fixed;
  top: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.7);
}

.og-maker-sidebar {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.main-menu .box-deck {
  margin-bottom: 0;
}

.Toastify__toast-container--top-center {
  top: -1px !important;
  padding: 0 !important;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0 0 5px 5px;
  /*box-shadow: 0 0 8px 8px rgb(0 0 0 / 10%);*/
}

.Toastify__toast.notification {
  text-align: center;
  padding: 0;
  cursor: auto;
  min-height: 48px;
  background: none;
  box-shadow: none;
  margin: 0;
}

.card-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: white !important;
  height: 48px;
  padding: 0 !important;
}

.make-viewer {
  /*min-height: -webkit-fill-available !important;*/
  height: 60vh;
  padding: 0 !important;
  position: fixed;
  left: 0;
  top: 48px;
  width: 100%;
}

#model-footer {
  padding: 0;
  position: sticky;
  bottom: 0;
  z-index: 100;
  background: white !important;
  min-height: 48px;
}

#model-footer .btn {
  border: none;
}

#model-footer .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#model-footer .btn-group > .btn:not(:first-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.blinking {
  -webkit-animation: glowing 3000ms ease infinite;
  -moz-animation: glowing 3000ms ease infinite;
  -o-animation: glowing 3000ms ease infinite;
  animation: glowing 3000ms ease infinite;
  /*background: transparent;*/
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7));
  /*background: radial-gradient(transparent, transparent); */
}

@-webkit-keyframes glowing {
  0% {
    background-color: none;
    -webkit-box-shadow: 0;
  }
  50% {
    background-color: rgba(28, 182, 245, 0.2);
    -webkit-box-shadow: 0 0 5px rgba(28, 182, 245, 0.2);
  }
  100% {
    background-color: none;
    -webkit-box-shadow: 0;
  }
}

@keyframes glowing {
  0% {
    background-color: none;
    box-shadow: 0;
  }
  50% {
    background-color: rgba(28, 182, 245, 0.2);
    box-shadow: 0 0 5px rgba(28, 182, 245, 0.2);
  }
  100% {
    background-color: none;
    box-shadow: 0;
  }
}

.blinking:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
  background: #6c757d;
}

label {
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
